<template>
  <div>
    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <RegistrationAttestation v-else :data="data">
      <template #title>
        <VTitle class="flex justify-baseline items-center route-title">
          {{ $t("app.attestations", 2) }}

          <VHint class="ml-4 text-base">
            {{ $t("hints.learning_attestations") }}
          </VHint>
        </VTitle>
      </template>
    </RegistrationAttestation>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import VHint from "@/components/VHint";
import RegistrationAttestation from "@/components/templates/RegistrationAttestation";

export default {
  components: {
    VTitle,
    VHint,
    RegistrationAttestation
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { request } = useRequest();
    const { getText } = useTexts();

    // Data
    const data = ref({});
    const isLoading = ref(false);

    // Computed
    const attestation = computed(() => data.value?.attestation);
    const texts = computed(() => attestation.value?.texts);
    const name = computed(() => getText(texts.value, "name"));
    const documentTitle = computed(() => {
      const title = `${t("app.attestations", 2)} - ${t("app.learning")}`;

      return name.value ? `${name.value} ${title}` : title;
    });

    // Methods
    const getData = async () => {
      const response = await request({
        endpoint: "learning.attestations.read",
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data ?? {};
    };

    // Lifecycle Hooks
    onMounted(async () => {
      isLoading.value = true;
      await getData();
      isLoading.value = false;
    });

    return {
      data,
      isLoading,
      documentTitle
    };
  }
};
</script>
